// Require a unique title per page
export const PageTitleRegistry: Record<
  string,
  { title: string; path: string }
> = {
  ZEUS_LANDING_HOME: {
    title: "zeus--landing--home-page",
    path: "/",
  },
  ZEUS_LANDING_FIND_TUTORS: {
    title: "zeus--landing--find-tutors-page",
    path: "/find-tutors",
  },
  ZEUS_LANDING_RESET: {
    title: "zeus--landing--platform-cookie-reset",
    path: "/reset",
  },
} as const;

export default PageTitleRegistry;
