"use client";

import { useEffect } from "react";

import {
  enableActivityTracking,
  newTracker,
  trackPageView,
} from "@snowplow/browser-tracker";
import { ClientHintsPlugin } from "@snowplow/browser-plugin-client-hints";
import { GaCookiesPlugin } from "@snowplow/browser-plugin-ga-cookies";
import { PerformanceNavigationTimingPlugin } from "@snowplow/browser-plugin-performance-navigation-timing";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import { FormTrackingPlugin } from "@snowplow/browser-plugin-form-tracking";

export const useInitSnowPlow = () => {
  useEffect(() => {
    newTracker(
      "mytutor-web-tracker",
      process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL ?? "",
      {
        appId: process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID,
        platform: "web",
        plugins: [
          FormTrackingPlugin(),
          PerformanceNavigationTimingPlugin(),
          ClientHintsPlugin(true),
          GaCookiesPlugin(),
          LinkClickTrackingPlugin(),
        ],
        cookieSameSite: "Lax",
        contexts: {
          webPage: true,
          session: true,
          browser: true,
        },
      }
    );

    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });
    enableLinkClickTracking();
  }, []);
};

// use per page when page ids are defined
export const useSnowPlowPageView = ({ title }: { title: string }) => {
  useEffect(() => {
    trackPageView({ title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};
